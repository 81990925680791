<template>
  <div>
    <v-header :params="{ title, leftIcon: true }"></v-header>
    <div>
        <van-tabs v-model="activeName" @change='activeNameChange'>
            <van-tab title="排列五" name="5">
            </van-tab>
            <van-tab title="七星彩" name="7">内容 2</van-tab>
            <van-tab title="达人" name="8">达人正在开发中</van-tab>
        </van-tabs>
        <div v-if="activeName==='5'||activeName==='7'">
            <div class="left">
                    <van-sidebar v-model="active" @change="onChange">
                        <van-sidebar-item  v-for="item in schemeArr" :key="item.id" :title="item.name"/>
                    </van-sidebar>
            </div>
            <div class="right">
                <button :class="buttonIndex===item.id?'onclick':'gray'" v-for="item in schemeArr[active].buttonArr" :key="item.id" @click="handlerClick(item.id)">{{item.name}}</button>
                <div>
                    <div class="title">
                        <p>
                            <span>{{activeName==='5'?'排列五':activeName==='7'?'七星彩':'达人'}}</span>-<span>{{schemeArr[activeName==='5'?active5:active7].name}}</span> <span class="show" @click="isShow=!isShow">{{isShow===false?'展开':'收起'}}</span>
                            <van-icon v-if="!isShow" name="arrow-down" size="15"/>
                            <van-icon v-else  name="arrow-up" size="15" />
                        </p>
                    </div>
                    <div class="content">
                        <div v-for="item in showInfo" :key="item.forumMstRankId" style="minHeight:1.2rem">
                            <van-image
                            round
                            width="0.8rem"
                            height="0.8rem"
                            :src="item.postAuthor.avatar_url"
                            @click="gotoAuther(item.userId,item.postAuthor.avatar_url,item.postAuthor.author)"
                            />
                            <span @click="gotoAuther(item.userId,item.postAuthor.avatar_url,item.postAuthor.author)" style="display: inline-block;width: 4rem;margin-left:0.1rem;">{{item.postAuthor.author}}</span>
                            <span style="margin-left: 0.8rem;color:red;">{{item.headContent}}</span>
                            <p v-html="item.bodyContent" v-show="isShow" style="padding: 0.1rem 0.8rem;"></p>
                        </div>
                    </div>
                    <div class="end">
                        <button  class="masterButoon" @click="becomesvip">大师包月</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import vHeader from '@/components/header.vue'
export default {
    components:{vHeader},
    data(){
        return{
            title: this.$route.meta.title,
            activeName:'5',
            active:this.activeName==='5'?this.active5:this.active7||0,
            active5:0,
            active7:0,
            schemeArr:[
                {id:0,name:'头尾',
                    buttonArr:[
                    {id:0,name:'命中次数',value:'wonTotals'},
                    {id:1,name:'回报率',value:'roi'},
                    {id:2,name:'大师期龄',value:'numOfAgesMaster'},
                    {id:3,name:'大师次数',value:'numOfTimesMaster'},
                    {id:4,name:'主攻中多',value:'wonMajorTotals'},
                    {id:5,name:'主攻连中',value:'wonMajorContinue'},
                    {id:6,name:'重点中多',value:'wonFocusTotals'},
                    {id:7,name:'重点连中',value:'wonFocusContinue'},
                    ],
                },
                {id:1,name:'中肚',
                    buttonArr:[
                    {id:0,name:'命中次数',value:'wonTotals'},
                    {id:1,name:'回报率',value:'roi'},
                    {id:2,name:'大师期龄',value:'numOfAgesMaster'},
                    {id:3,name:'大师次数',value:'numOfTimesMaster'},
                    {id:4,name:'主攻中多',value:'wonMajorTotals'},
                    {id:5,name:'主攻连中',value:'wonMajorContinue'},
                    {id:6,name:'重点中多',value:'wonFocusTotals'},
                    {id:7,name:'重点连中',value:'wonFocusContinue'},
                    ],
                },
                {id:2,name:'ABXX',
                    buttonArr:[
                    {id:0,name:'命中次数',value:'wonTotals'},
                    {id:1,name:'回报率',value:'roi'},
                    {id:2,name:'大师期龄',value:'numOfAgesMaster'},
                    {id:3,name:'大师次数',value:'numOfTimesMaster'},
                    {id:4,name:'主攻中多',value:'wonMajorTotals'},
                    {id:5,name:'主攻连中',value:'wonMajorContinue'},
                    {id:6,name:'重点中多',value:'wonFocusTotals'},
                    {id:7,name:'重点连中',value:'wonFocusContinue'},
                    ],
                },
                {id:3,name:'AXCX',
                    buttonArr:[
                    {id:0,name:'命中次数',value:'wonTotals'},
                    {id:1,name:'回报率',value:'roi'},
                    {id:2,name:'大师期龄',value:'numOfAgesMaster'},
                    {id:3,name:'大师次数',value:'numOfTimesMaster'},
                    {id:4,name:'主攻中多',value:'wonMajorTotals'},
                    {id:5,name:'主攻连中',value:'wonMajorContinue'},
                    {id:6,name:'重点中多',value:'wonFocusTotals'},
                    {id:7,name:'重点连中',value:'wonFocusContinue'},
                    ],
                },
                {id:4,name:'XBXD',
                    buttonArr:[
                    {id:0,name:'命中次数',value:'wonTotals'},
                    {id:1,name:'回报率',value:'roi'},
                    {id:2,name:'大师期龄',value:'numOfAgesMaster'},
                    {id:3,name:'大师次数',value:'numOfTimesMaster'},
                    {id:4,name:'主攻中多',value:'wonMajorTotals'},
                    {id:5,name:'主攻连中',value:'wonMajorContinue'},
                    {id:6,name:'重点中多',value:'wonFocusTotals'},
                    {id:7,name:'重点连中',value:'wonFocusContinue'},
                    ],
                },
                {id:5,name:'XXCD',
                    buttonArr:[
                    {id:0,name:'命中次数',value:'wonTotals'},
                    {id:1,name:'回报率',value:'roi'},
                    {id:2,name:'大师期龄',value:'numOfAgesMaster'},
                    {id:3,name:'大师次数',value:'numOfTimesMaster'},
                    {id:4,name:'主攻中多',value:'wonMajorTotals'},
                    {id:5,name:'主攻连中',value:'wonMajorContinue'},
                    {id:6,name:'重点中多',value:'wonFocusTotals'},
                    {id:7,name:'重点连中',value:'wonFocusContinue'},
                    ],
                },
                {id:6,name:'ABCX',
                    buttonArr:[
                    {id:0,name:'命中次数',value:'wonTotals'},
                    {id:1,name:'回报率',value:'roi'},
                    {id:2,name:'大师期龄',value:'numOfAgesMaster'},
                    {id:3,name:'大师次数',value:'numOfTimesMaster'},
                    {id:4,name:'主攻中多',value:'wonMajorTotals'},
                    {id:5,name:'主攻连中',value:'wonMajorContinue'},
                    {id:6,name:'重点中多',value:'wonFocusTotals'},
                    {id:7,name:'重点连中',value:'wonFocusContinue'},
                    ],
                },
                {id:7,name:'ABXD',
                    buttonArr:[
                    {id:0,name:'命中次数',value:'wonTotals'},
                    {id:1,name:'回报率',value:'roi'},
                    {id:2,name:'大师期龄',value:'numOfAgesMaster'},
                    {id:3,name:'大师次数',value:'numOfTimesMaster'},
                    {id:4,name:'主攻中多',value:'wonMajorTotals'},
                    {id:5,name:'主攻连中',value:'wonMajorContinue'},
                    {id:6,name:'重点中多',value:'wonFocusTotals'},
                    {id:7,name:'重点连中',value:'wonFocusContinue'},
                    ],
                },
                {id:8,name:'AXCD',
                    buttonArr:[
                    {id:0,name:'命中次数',value:'wonTotals'},
                    {id:1,name:'回报率',value:'roi'},
                    {id:2,name:'大师期龄',value:'numOfAgesMaster'},
                    {id:3,name:'大师次数',value:'numOfTimesMaster'},
                    {id:4,name:'主攻中多',value:'wonMajorTotals'},
                    {id:5,name:'主攻连中',value:'wonMajorContinue'},
                    {id:6,name:'重点中多',value:'wonFocusTotals'},
                    {id:7,name:'重点连中',value:'wonFocusContinue'},
                    ],
                },
                {id:9,name:'XBCD',
                    buttonArr:[
                    {id:0,name:'命中次数',value:'wonTotals'},
                    {id:1,name:'回报率',value:'roi'},
                    {id:2,name:'大师期龄',value:'numOfAgesMaster'},
                    {id:3,name:'大师次数',value:'numOfTimesMaster'},
                    {id:4,name:'主攻中多',value:'wonMajorTotals'},
                    {id:5,name:'主攻连中',value:'wonMajorContinue'},
                    {id:6,name:'重点中多',value:'wonFocusTotals'},
                    {id:7,name:'重点连中',value:'wonFocusContinue'},
                    ],
                },
                {id:10,name:'芝麻',
                    buttonArr:[
                    {id:0,name:'命中次数',value:'wonTotals'},
                    {id:1,name:'回报率',value:'roi'},
                    {id:2,name:'大师期龄',value:'numOfAgesMaster'},
                    {id:3,name:'大师次数',value:'numOfTimesMaster'},
                    {id:4,name:'主攻中多',value:'wonMajorTotals'},
                    {id:5,name:'主攻连中',value:'wonMajorContinue'},
                    {id:6,name:'重点中多',value:'wonFocusTotals'},
                    {id:7,name:'重点连中',value:'wonFocusContinue'},
                    ],
                },
                {id:11,name:'二字现',
                    buttonArr:[
                    {id:0,name:'命中次数',value:'wonTotals'},
                    {id:1,name:'回报率',value:'roi'},
                    {id:2,name:'大师期龄',value:'numOfAgesMaster'},
                    {id:3,name:'大师次数',value:'numOfTimesMaster'},
                    
                    ],
                },
                {id:12,name:'三字现',
                    buttonArr:[
                    {id:0,name:'命中次数',value:'wonTotals'},
                    {id:1,name:'回报率',value:'roi'},
                    {id:2,name:'大师期龄',value:'numOfAgesMaster'},
                    {id:3,name:'大师次数',value:'numOfTimesMaster'},
                    
                    ],
                },
                {id:13,name:'定头',
                    buttonArr:[
                    {id:0,name:'命中次数',value:'wonTotals'},
                    {id:1,name:'回报率',value:'roi'},
                    {id:2,name:'大师期龄',value:'numOfAgesMaster'},
                    {id:3,name:'大师次数',value:'numOfTimesMaster'},
                    {id:4,name:'主攻中多',value:'wonMajorTotals'},
                    {id:5,name:'主攻连中',value:'wonMajorContinue'},
                    {id:6,name:'重点中多',value:'wonFocusTotals'},
                    {id:7,name:'重点连中',value:'wonFocusContinue'},
                    ],
                },
                {id:14,name:'定百',
                    buttonArr:[
                    {id:0,name:'命中次数',value:'wonTotals'},
                    {id:1,name:'回报率',value:'roi'},
                    {id:2,name:'大师期龄',value:'numOfAgesMaster'},
                    {id:3,name:'大师次数',value:'numOfTimesMaster'},
                    {id:4,name:'主攻中多',value:'wonMajorTotals'},
                    {id:5,name:'主攻连中',value:'wonMajorContinue'},
                    {id:6,name:'重点中多',value:'wonFocusTotals'},
                    {id:7,name:'重点连中',value:'wonFocusContinue'},
                    ],
                },
                {id:15,name:'定十',
                    buttonArr:[
                    {id:0,name:'命中次数',value:'wonTotals'},
                    {id:1,name:'回报率',value:'roi'},
                    {id:2,name:'大师期龄',value:'numOfAgesMaster'},
                    {id:3,name:'大师次数',value:'numOfTimesMaster'},
                    {id:4,name:'主攻中多',value:'wonMajorTotals'},
                    {id:5,name:'主攻连中',value:'wonMajorContinue'},
                    {id:6,name:'重点中多',value:'wonFocusTotals'},
                    {id:7,name:'重点连中',value:'wonFocusContinue'},
                    ],
                },
                {id:16,name:'定尾',
                    buttonArr:[
                    {id:0,name:'命中次数',value:'wonTotals'},
                    {id:1,name:'回报率',value:'roi'},
                    {id:2,name:'大师期龄',value:'numOfAgesMaster'},
                    {id:3,name:'大师次数',value:'numOfTimesMaster'},
                    {id:4,name:'主攻中多',value:'wonMajorTotals'},
                    {id:5,name:'主攻连中',value:'wonMajorContinue'},
                    {id:6,name:'重点中多',value:'wonFocusTotals'},
                    {id:7,name:'重点连中',value:'wonFocusContinue'},
                    ],
                },
                {id:17,name:'杀头',
                    buttonArr:[
                    {id:0,name:'命中次数',value:'wonTotals'},
                    {id:1,name:'回报率',value:'roi'},
                    {id:2,name:'大师期龄',value:'numOfAgesMaster'},
                    {id:3,name:'大师次数',value:'numOfTimesMaster'},
                    {id:4,name:'连中次数',value:'totalNowContinue'},
                    {id:5,name:'命中率',value:'wonCountRate'},
                    
                    ],
                },
                {id:18,name:'杀百',
                    buttonArr:[
                    {id:0,name:'命中次数',value:'wonTotals'},
                    {id:1,name:'回报率',value:'roi'},
                    {id:2,name:'大师期龄',value:'numOfAgesMaster'},
                    {id:3,name:'大师次数',value:'numOfTimesMaster'},
                    {id:4,name:'连中次数',value:'totalNowContinue'},
                    {id:5,name:'命中率',value:'wonCountRate'},
                    ],
                },
                {id:19,name:'杀十',
                    buttonArr:[
                    {id:0,name:'命中次数',value:'wonTotals'},
                    {id:1,name:'回报率',value:'roi'},
                    {id:2,name:'大师期龄',value:'numOfAgesMaster'},
                    {id:3,name:'大师次数',value:'numOfTimesMaster'},
                    {id:4,name:'连中次数',value:'totalNowContinue'},
                    {id:5,name:'命中率',value:'wonCountRate'},
                    ],
                },
                {id:20,name:'杀尾',
                    buttonArr:[
                    {id:0,name:'命中次数',value:'wonTotals'},
                    {id:1,name:'回报率',value:'roi'},
                    {id:2,name:'大师期龄',value:'numOfAgesMaster'},
                    {id:3,name:'大师次数',value:'numOfTimesMaster'},
                    {id:4,name:'连中次数',value:'totalNowContinue'},
                    {id:5,name:'命中率',value:'wonCountRate'},
                    ],
                },
                {id:21,name:'头尾合',
                    buttonArr:[
                    {id:0,name:'命中次数',value:'wonTotals'},
                    {id:1,name:'回报率',value:'roi'},
                    {id:2,name:'大师期龄',value:'numOfAgesMaster'},
                    {id:3,name:'大师次数',value:'numOfTimesMaster'},
                    
                    ],
                },
                {id:22,name:'中肚合',
                    buttonArr:[
                    {id:0,name:'命中次数',value:'wonTotals'},
                    {id:1,name:'回报率',value:'roi'},
                    {id:2,name:'大师期龄',value:'numOfAgesMaster'},
                    {id:3,name:'大师次数',value:'numOfTimesMaster'},
                    
                    ],
                },
                {id:23,name:'稳码',
                    buttonArr:[
                    {id:0,name:'命中次数',value:'wonTotals'},
                    {id:1,name:'回报率',value:'roi'},
                    {id:2,name:'大师期龄',value:'numOfAgesMaster'},
                    {id:3,name:'大师次数',value:'numOfTimesMaster'},
                    
                    ],
                },
                {id:24,name:'死数',
                    buttonArr:[
                    {id:0,name:'命中次数',value:'wonTotals'},
                    {id:1,name:'回报率',value:'roi'},
                    {id:2,name:'大师期龄',value:'numOfAgesMaster'},
                    {id:3,name:'大师次数',value:'numOfTimesMaster'},
                    
                    ],
                },
            ],
            // buttonArr:[
            //     {id:0,name:'命中次数',value:'wonTotals'},
            //     {id:1,name:'回报率',value:'roi'},
            //     {id:2,name:'大师期龄',value:'numOfAgesMaster'},
            //     {id:3,name:'大师次数',value:'numOfTimesMaster'},
            //     {id:4,name:'主攻中多',value:'wonMajorTotals'},
            //     {id:5,name:'主攻连中',value:'wonMajorContinue'},
            //     {id:6,name:'重点中多',value:'wonFocusTotals'},
            //     {id:7,name:'重点连中',value:'wonFocusContinue'},
            // ],
            buttonIndex:this.activeName==='5'?this.buttonIndex5:this.buttonIndex7||0,
            buttonIndex5:0,
            buttonIndex7:0,
            isShow:false,
            famesInfo:[],
            showInfo:[],
        }
    },
    methods:{
        onChange(index){
            if(this.activeName==='5'){
                this.active5=index;
            }else{
                this.active7=index;
            }
            this.handlerClick(0);
            // console.log('change',this.active5,this.active7);
            // this.$nextTick();
            // this.sortList();

        },
        handlerClick(index){
            this.buttonIndex=index;
            if(this.activeName==='5'){
                this.buttonIndex5=index;

            }else{
                this.buttonIndex7=index;
            }
            // console.log('handlerClick',this.buttonIndex5,this.buttonIndex7);
            this.sortList();

        },
        async getMasterList(){
            let data={
                csKind: '10',
                lotteryKind: this.activeName,
                // status: 0,
                systemId: 100001,
                unionCheckCode:''
            }
            const res = await this.$HTTP.post(this, 'API_RANK_FORUM_MASTER_LIST', data, { headers: { access_token: this.$store.state.common.loginInfo.access_token } }, true)
            if (res.statusCode === 200) {
                for (let item of res.data) {
                    if(item.roi!==null){
                        item.roi=parseFloat(item.roi).toFixed(2)
                    }
                    try {
                        item.postAuthor = item.postAuthor ? JSON.parse(item.postAuthor) : {}
                    } catch (e) {
                        item.postAuthor = {}
                    }
                }
                this.famesInfo=res.data
                // console.log('名人堂信息',this.famesInfo)
                this.sortList();

            }
        },
        sortList(){
            let that =this;

            const arr=that.famesInfo.filter((item)=>{
                    if(item.categoryName===that.schemeArr[that.active].name){
                        // console.log('@',that.active5,that.schemeArr[that.active].name)
                    return item
                    }
                }).sort(function(a, b){
                    // console.log('a,b',b[that.schemeArr[that.active].buttonArr[that.buttonIndex].value])
                    return b[that.schemeArr[that.active].buttonArr[that.buttonIndex].value]-a[that.schemeArr[that.active].buttonArr[that.buttonIndex].value]
                    })
            const filter=arr.map((item)=>{
                let headContent='';
                let bodyContent='近'+item.totalsRecently+'中'+item.wonTotals+'，最高'+item.totalTimesContinue+'现'+item.totalNowContinue+'连中'
                if(item.wonMajorTotals!==null&&item.wonMajorTotals!==0){
                    bodyContent+='<br/>'+'主攻中'+item.wonMajorTotals+'，最高'+item.wonMajorContinue+'连中'
                }
                if(item.wonFocusTotals!==null&&item.wonFocusTotals!==0){
                    bodyContent+='<br/>'+'重点中'+item.wonFocusTotals+'，最高'+item.wonFocusContinue+'连中'
                }
                switch(that.schemeArr[that.active].buttonArr[that.buttonIndex].value){
                    case 'wonTotals':
                        headContent='近'+item.totalsRecently+'中'+item.wonTotals
                        break;
                    case 'roi':
                        headContent=item.roi+'%'
                        break;
                    case 'numOfAgesMaster':
                        headContent=item.numOfAgesMaster+'期'
                        bodyContent='从第'+item.periodFrom+'期成为【'+item.categoryName+'】大师，至今已经连续'+item.numOfAgesMaster+'期一直保持大师水平'
                        break;
                    case 'numOfTimesMaster':
                        headContent=item.numOfTimesMaster+'次'
                        bodyContent='一共'+item.numOfTimesMaster+'次，上过【'+item.categoryName+'】大师榜'
                        break;
                    case 'wonMajorTotals':
                        headContent='主攻中：'+item.wonMajorTotals
                        break;
                    case 'wonMajorContinue':
                        headContent='主攻连中：'+item.wonMajorContinue
                        break;
                    case 'wonFocusTotals':
                        headContent='重点中：'+item.wonFocusTotals
                        break;
                    case 'wonFocusContinue':
                        headContent='重点连中：'+item.wonFocusContinue
                        break;
                    case 'totalNowContinue':
                        headContent='连中：'+item.totalNowContinue
                        break;
                    case 'wonCountRate':
                        headContent=item.wonCountRate+'%'
                        break;
                }
                let data ={
                    headContent,
                    bodyContent,
                    ...item
                }
                return data
            })
            // console.log('编辑后数据',filter)
            this.showInfo=filter
                // console.log('处理后的数据',this.showInfo)
        },
        becomesvip(){
            this.$router.push({
                name:'vippayment',
                query:{
                    userid:this.$store.state.common.loginInfo.userId,
                    access_token:this.$store.state.common.loginInfo.access_token,
                    
                }
            })

        },
        activeNameChange(){
            this.active=this.activeName==='5'?this.active5:this.active7
            if(this.activeName==='5'||this.activeName==='7'){
                this.getMasterList();

            }
            // console.log('@')
        },
        gotoAuther(id,url,name){
            this.$router.push({
                name:'friendsCircle/authorinfo',
                query:{
                    postid:id,
                    posturl:url,
                    postname:name
                }

            })
        }
    },
    created(){
        this.getMasterList();
    },
    mounted(){
    }
}
</script>

<style lang="less" scoped>
.left{
    width: 2rem;
    position: fixed;
    top: 2.2rem;
    left: 0;
    bottom: 0;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 0;
    }
}
.right{
    position: fixed;
    top: 2.5rem;
    left: 2rem;
    bottom: 1.8rem;
    right: 0;
    overflow-y: scroll;
    font-size: 0.35rem;
    font-weight:900;
    button{
        width:1.8rem;
        height:0.8rem;
        margin: 0.1rem;
        
    }
    .title{
        font-size: 0.4rem;

    }
    .content{
        font-size: 0.4rem;

    }
    .end{
        .masterButoon{
            color: white;
            background-color: red;
            width: 6rem;
            height: 1rem;
            margin: 10px 42px;
            border-radius: 10px;
            border: none;
            font-size: 0.5rem;
            
        }
    }
}
.onclick{
    
    background-color:red;
    color:white;
    border:none;
}
.gray{
    background-color: rgba(128, 128, 128, 0.114);
    color:gray;
    border:none;
}
.show{
    margin-left:4rem;
}

</style>